import React from 'react'
import tw from 'twin.macro'
import { Link } from 'gatsby'
import { css } from '@emotion/react'

import { Container, HeaderStyle, CopyStyle, FAQStyle, LinkButton } from './UI'
import { Disclosure } from '@headlessui/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import FaqContent from './FaqContent'

import chevron from '../images/icons/chevron.svg'
import arrow from '../images/icons/footer-form-submit-arrow.svg'

const FrequentlyAskedQuestions = ({ image, questions, headline, subhead, hospitalityRoute = false }) => (
  <>
    {questions && (
      <section tw="max-w-screen-xl mx-auto">
        <div tw="pb-14 flex flex-col lg:(py-14 grid grid-cols-[1fr repeat(12, calc(1024px/12)) 1fr])">
          {/* Image */}
          <GatsbyImage
            tw="w-full max-h-[550px] mb-11 lg:(col-span-6 mb-0)"
            image={image.gatsbyImageData}
            alt={image.filename}
          />
          {/* Right side */}
          <div tw="mx-7 lg:(col-start-8 col-span-6 flex flex-col justify-center)">
            <h2 css={[HeaderStyle, tw`mb-7 lg:(mb-[22px])`]}>{headline}</h2>
            <p css={[CopyStyle, tw`mb-[42px] lg:(mb-8)`]}>{subhead}</p>
            {/* FAQs */}
            {questions.map((question, index) => (
              <Disclosure
                as="div"
                tw="border border-[#202C3A] mb-4"
                key={`frequently-asked-question-${index}`}
              >
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      css={[
                        FAQStyle,
                        tw`flex justify-between items-center py-7 px-8 w-full text-left`,
                      ]}
                    >
                      <h3>{question.question}</h3>
                      <img
                        src={chevron}
                        css={[
                          open &&
                          css`
                              transform: rotate(180deg);
                            `,
                        ]}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel tw="px-8">
                      <FaqContent raw={question.answer.raw} />
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
            {/* FAQ CTA */}
            <div tw="mt-8 lg:(mt-7)">
              {hospitalityRoute ?
                <Link to="/hospitality/faq" css={[CopyStyle, tw`flex items-center`]}>
                  View all <img src={arrow} tw="w-8 ml-3" />
                </Link>
                :
                <Link to="/faq" css={[CopyStyle, tw`flex items-center`]}>
                  View all <img src={arrow} tw="w-8 ml-3" />
                </Link>
              }
            </div>
          </div>
        </div>
      </section>
    )}
  </>
)

export default FrequentlyAskedQuestions
